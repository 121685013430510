<template>
  <b-modal
    id="modal-1"
    v-model="homeModalObj.show"
    modal-class="login-modal"
    :centered="true"
    :hide-footer="true"
    :no-close-on-backdrop="true"
    @close="close"
  >
  <div slot="modal-header" class="contact fe">
    <img src="../../../assets/images/login_modal_phone.png">
    <div class="number">
      <div>咨询热线</div>
      <div>{{ siteData.phone }}</div>
    </div>
  </div>
  <div class="content fb">
    <div class="left_text">
      <p>谨遵中国银行业监督管理委员会发布的《信托公司集合资金信托计划管理办法》之规定，只向特定投资者展示信托产品信息，不构成任何投资推介建议。</p>
      <p>阁下如有意进行信托投资，请承诺符合《信托公司集合资金信托计划管理办法》之规定合格投资者的条件。</p>
      <p>合格投资者是指具备相应风险识别能力和风险承担能力，投资于单只资产管理产品不低于一定金额目符合下列条件的自然人和法人或者其他组织:具有2年以上投资经历，且满足以下条件之一:</p>
      <p>(一)家庭金融净资产不低于300万元，家庭金融资产不低于500万元，</p>
      <p>(二)近3年本人年均收入不低于40万元;</p>
      <p>(三)最近1年未净资产不低于1000万元的法人单位:金融管理部门视为合格投资者的其他情形。</p>
      <p>或满足符合下列条件之一，能够识别、判断和承担信托计划相应风险的人</p>
    </div>
    <div class="right_form">
      <div class="container">
        <h2>一站式咨询服务平台</h2>
        <div class="form">
          <div class="auth_form_item fs">
            <input
              placeholder="请输入您的姓名"
              v-model="formData.userName"
            />
          </div>
          <div class="auth_form_item phone_item fs">
            <input
              placeholder="请输入您的手机号"
              v-model="formData.phoneNum"
            />
          </div>
          <div class="auth_form_item code_item fs">
            <div class="txtCode fs">
              <input
                placeholder="请输入验证码"
                maxlength="6"
                v-model="formData.veriCode"
              />
              <span
                class="send_btn fc"
                @click="sendMsg"
              >{{ msgTime ? msgTime : '发送验证码' }}</span>
            </div>
          </div>
        </div>
        <div class="split">
          <img src="../../../assets/images/login_modal_split.png">
        </div>
        <div class="agree">
          <input type="checkbox" name="btn" id="btn1" v-model="checked"/>
          我接受<b>《网站条款》</b>
        </div>
        <div class="button">
          <button @click="submit" :disabled="!checked">登录进入</button>
          <button @click="close" :disabled="!checked">暂不登录</button>
        </div>
      </div>
    </div>
  </div>
  </b-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { validate } from '@/utils'
import { addAuthInfo, sendMessage } from '@/api/api'
const formData = {
  userName: '',
  phoneNum: '',
  veriCode: ''
}
export default {
  data() {
    return {
      show: true,
      formData: {
        ...formData
      },
      authRes: false,
      msgTime: 0,
      checked: true
    }
  },
  computed: {
    ...mapState(['homeModalObj', 'siteData'])
  },
  methods: {
    ...mapMutations(['setHomeModalObj']),
    close () {
      this.setHomeModalObj({
        show: false,
        time: Date.now()
      })
      this.formData = {
        ...formData
      }
    },
    sendMsg () {
      let regPhone = /^1[3456789]\d{9}$/
      if (!regPhone.test(this.formData.phoneNum)) {
        return this.$bvModal.msgBoxOk('请输入正确手机号', {
          title: '提示',
          size: 'sm',
          modalClass: 'tip-modal',
          okTitle: '知道了',
          autoHideDelay: 2000,
        })
      }
      if (this.msgTime) return
      sendMessage(this.formData.phoneNum).then(() => {
        this.$bvModal.msgBoxOk('验证码发送成功', {
          title: '提示',
          size: 'sm',
          modalClass: 'tip-modal',
          okTitle: '知道了',
          autoHideDelay: 2000,
        })
        this.msgTime = 60
        this.countDown()
      })
    },
    countDown () {
      setTimeout(() => {
        this.msgTime--
        if (!this.msgTime) return
        this.countDown()
      }, 1000)
    },
    submit () {
      let errMsg = validate(this.formData)
      console.log(errMsg, 'errMsg+++++++')
      if (errMsg) {
        return this.$bvModal.msgBoxOk(errMsg, {
          title: '提示',
          size: 'sm',
          modalClass: 'tip-modal',
          okTitle: '知道了',
          autoHideDelay: 2000,
        })
      }
      let params = {
        ...this.formData,
        productName: this.productName || '',
        siteId: this.$store.state.siteId
      }
      addAuthInfo(params).then(() => {
        this.$bvModal.msgBoxOk('提交成功', {
          title: '提示',
          size: 'sm',
          centered: true,
          modalClass: 'tip-modal',
          okTitle: '知道了',
          autoHideDelay: 2000,
        });
        this.$store.commit('setAuthRes', {
          createTime: Date.now(),
          ...this.formData
        })
        this.close()
        
      })
    }
  }
}
</script>

<style lang="scss">
.login-modal {
  .modal-dialog {
    max-width: 830px;
    .modal-header {
      height: 70px;
      box-sizing: border-box;
      background-image: url(../../../assets/images/login_modal_head.jpeg);
      background-size: 100% 100%;
      .close {
        display: none;
      }
      .contact {
        width: 100%;
        img {
          height: 43px;
        }
        .number {
          color: #fff;
          margin-left: 6px;
          div:first-child {
            margin-bottom: 3px;
          }
        }
      }
    }
    .modal-body {
      padding: 0;
    }
    .content {
      > div {
        &.left_text {
          width: 56%;
          // height: 423px;
          // background: url(../../../assets/images/login_modal_body.jpeg)  no-repeat center -1px;
          border-right: 1px #c9c9c9 solid;
          border-top: none;
          padding: 20px 20px;
          h1 {
            font-size: 22px;
            color: #ec6a41;
            text-align: center;
            line-height: 72px;
            margin-top: 10px;
          }
          > div, > p {
            font-size: 13px;
            line-height: 24px;
            color: #595758;
            margin-bottom: 8px;
            b {
              font-size: 13px;
              color: #000;
            }
          }
        }
        &.right_form {
          flex: 1;
          align-self: flex-start;
          padding-top: 30px;
          .container {
            padding: 0px 12% 45px 13%;
            > * {
              width: 100%;
              text-align: center;
              .auth_form_item {
                background: url(../../../assets/images/login_modal_form_ico1.png) no-repeat 15px 8px;
                padding-left: 36px;
                border: 1px #999 solid;
                box-sizing: border-box;
                overflow: hidden;
                border-radius: 8px;
                &.phone_item {
                  background-image: url(../../../assets/images/login_modal_form_ico2.png)
                }
                &.code_item {
                  border: none;
                  padding: 0;
                  border-radius: 0;
                  .txtCode {
                    flex: 1;
                    border-radius: 8px;
                    overflow: hidden;
                    input {
                      border: 1px #999 solid;
                      border-radius: 8px;
                    }
                  }
                }
                
                input {
                  flex: 1;
                  height: 40px;
                  border: none;
                }
              }
            }
            .split {
              margin-bottom: 20px;
            }
            .agree {
              text-align: left;
              color: #333;
              font-size: 14px;
              input {
                width: 14px;
                height: 14px;
                position: relative;
                &::before {
                  content: "";
                  position: absolute;
                  top: 0;
                  left: 0;
                  background: #fff;
                  width: 100%;
                  height: 100%;
                  border: 1px solid #d9d9d9;
                }
                &:checked::before{
                  content: "\2713";
                  background-color: #fff;
                  position: absolute;
                  top: 0;
                  left: 0;
                  width:100%;
                  color:#ed5313;
                  font-size: 14px;
                  font-weight: bold;
                  border-radius:2px;
                }

              }
              b {
                color: #e85603;
              }
            }
            .button {
              margin-top: 20px;
              text-align: left;
               button {
                width: 120px;
                background-color: #e85603;
                color: #fff;
                border: 0;
                height: 2.5em;
                border-radius: 5px;
                font-size: 1.15em;
                font-size: 15px;
                margin-right: 15px;
                &:disabled {
                  background: #ccc;
                }
              }
            }
          }
          h2 {
            font-size: 14px;
            margin-bottom: 20px;
          }
          
        }
      }
      
    }
  }
}
</style>