<template>
  <div id="app">
    <Layout></Layout>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  getDeviceInfo
} from '@/utils'
import { siteItem, cateTree } from '@api/api'
import Layout from '@/views/Layout'
export default {
  components: {
    Layout
  },
  computed: {
    ...mapState(['authRes', 'siteId', 'homeModalObj']),
  },
  async created () {
    cateTree().then(res => {
      this.$store.commit('setCateList', res)
    })
    siteItem({
      siteId: this.$store.state.siteId
    }).then(res => {
      if (res.modalShow) {
        if (!this.homeModalObj?.show && Number(this.homeModalObj?.time || 0) < Date.now() - 86400000) {
          this.$store.commit('setHomeModalObj', {
            show: true
          })
        }
      } else {
        this.$store.commit('setHomeModalObj', {
          show: false
        })
      }
      
      this.$store.commit('setSiteData', res)
    })
    let obj = await getDeviceInfo()
    this.$store.commit('setDeviceInfo', obj)
    if (this.authRes && this.authRes.createTime && (Number(this.authRes.createTime) < Date.now() - 86400000)) {
      this.$store.commit('setAuthRes', null)
    }
    
  }
}
</script>

<style lang="scss">
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
